import React from 'react';
import styled from 'styled-components';
import {useI18next, Helmet} from 'gatsby-plugin-react-i18next';
import {styles, media, colors, links} from 'global.config';
import Stars from 'components/Stars';
import Button from 'components/Button';
import Container from 'components/Container';
import SectionHeader from 'components/SectionHeader';
import TrustedBy from 'components/TrustedBy';
import Pricing from 'components/Pricing';
import {PricingPlan} from 'components/Pricing/PricingPlanInterface';
import {AnchorLink} from 'components/AnchorLink';

import EmailMarketingSoftwareImage from 'images/email-marketing-software.svg';
import avoidSpam from 'images/Email-marketing-software-to-help-you-Avoid-spam.svg';
import payLess from 'images/Pay-less-for-your-Email-marketing-software.svg';
import simpleToUse from 'images/Email-marketing-software-Simple-to-use.svg';
import TestRide from 'images/test-ride-image.svg';

const IndexSection = styled.section`
  text-align: center;
  color: #fff;
  position: relative;
`;

const IndexSectionContainer = styled.div`
  padding: 135px ${styles.containerPadding} 80px;
  position: relative;
  min-height: calc(100vh - 170px);
  margin: auto;
  max-width: ${styles.containerWidth};
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 5%;
    right: 5%;
    border-bottom: 1px solid #e5e5e5;
  }
  @media ${media.mobile} {
    padding-top: 150px;
  }
`;

const IndexSectionImage = styled.img`
  display: block;
  margin: 0 auto;
  @media ${media.mobile} {
    width: 235px;
    margin-bottom: 20px;
  }
`;

const IndexSectionSummary = styled.p`
  font-size: 16px;
  line-height: 20px;
  max-width: 400px;
  margin: 30px auto;
  font-weight: 300;
`;

const FeatureSection = styled.section`
  color: #fff;
  text-align: center;
  margin: 0;
  @media ${media.mobile} {
    margin-bottom: 80px;
  }
`;

const FeatureContainer = styled(Container)`
  display: flex;
  justify-content: space-around;
  padding: 60px ${styles.containerPadding};
  @media ${media.mobile} {
    flex-direction: column;
    align-items: center;
  }
`;

const FeatureFigure = styled.figure`
  color: #fff;
  text-align: center;
  max-width: 260px;
  margin: 0;
`;

const FeatureCaption = styled.figcaption`
  h2 {
    font-size: 29px;
    font-weight: 400;
    margin: 2px 0 15px;
    line-height: 31px;
    height: 93px;
  }
  a {
    color: ${colors.primary};
    text-decoration: underline;
  }
  @media ${media.tablet} {
    p {
      font-size: 16px;
    }
    h2 {
      font-size: 22px;
    }
  }
  @media ${media.mobile} {
    p {
      font-size: 16px;
    }
    h2 {
      font-size: 20px;
      margin: 0 0 10px;
    }
  }
`;

const IllustratedContent = styled.div`
  max-width: 480px;
  margin-left: 6.3%;
  ul {
    color: ${colors.textLight};
    list-style: none;
    margin: 0 0 15px;
    padding: 0;
    line-height: 20px;
  }
  p {
    color: ${colors.textLight};
  }
`;
const IllustratedContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px ${styles.containerPadding} 85px;
  img {
    max-width: 100%;
    @media ${media.tablet} {
      max-width: 40%;
    }
  }
  @media ${media.mobile} {
    display: block;
  }
`;

const MainPage: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <Helmet title={t('Email Marketing Automation')}>
        <meta
          name="description"
          content={t(
            'Use MoonMail, the best Email Marketing Automation platform. Try it now for free!'
          )}
        />
      </Helmet>
      <div className="index-page">
        <Stars>
          <IndexSection>
            <IndexSectionContainer>
              <IndexSectionImage
                src={EmailMarketingSoftwareImage}
                alt={t('Email marketing software')}
                title={t('OmniChannel Communication Platform')}
                width={296}
                height={201}
              />
              <h1>{t('Email Marketing Automation')}</h1>
              <h2>
                {t(
                  '93% of customers are more likely to purchase when you connect with them on multiple channels'
                )}
              </h2>
              <IndexSectionSummary>{t('For the Professional Marketer')}</IndexSectionSummary>
              <p>
                <Button
                  href={links.signUp}
                  title={t('OmniChannel Communication Platform to fly to the moon')}>
                  {t('Sign Up Free')}
                </Button>
              </p>
            </IndexSectionContainer>
          </IndexSection>
          <FeatureSection>
            <FeatureContainer>
              <FeatureFigure>
                <img
                  src={simpleToUse}
                  alt="OmniChannel Communication Platform which is Simple to use"
                  width="65"
                  height="79"
                />
                <FeatureCaption>
                  <h2>{t('47% Revenue increase per customer')}</h2>
                  <AnchorLink
                    href={links.signUp}
                    title={t('OmniChannel Communication Platform for your grandma!')}>
                    {t('Simply Grow your revenue')}
                  </AnchorLink>
                </FeatureCaption>
              </FeatureFigure>
              <FeatureFigure>
                <img
                  src={avoidSpam}
                  alt={t('OmniChannel Communication Platform to help you Avoid spam')}
                  width="62"
                  height="85"
                />
                <FeatureCaption>
                  <h2>{t('39% Improvement in customer satisfaction')}</h2>
                  <AnchorLink
                    href={links.signUp}
                    title={t('OmniChannel Communication Platform to go on fire!')}>
                    {t('Make your users happier')}
                  </AnchorLink>
                </FeatureCaption>
              </FeatureFigure>
              <FeatureFigure>
                <img
                  src={payLess}
                  alt={t('Pay less for your OmniChannel Communication Platform')}
                  width="85"
                  height="89"
                />
                <FeatureCaption>
                  <h2>{t('33% Growth in customer lifetime value')}</h2>
                  <a
                    href={links.signUp}
                    title={t('OmniChannel Communication Platform to go to the Orbit')}>
                    {t('Boost your LFV')}
                  </a>
                </FeatureCaption>
              </FeatureFigure>
            </FeatureContainer>
          </FeatureSection>
          <TrustedBy />
        </Stars>
        <section className="test-ride illustrated-section">
          <IllustratedContainer>
            <img
              src={TestRide}
              alt={t('Do a test ride on our OmniChannel Communication Platform')}
            />
            <IllustratedContent>
              <SectionHeader>{t('Fancy trying MoonMail?')}</SectionHeader>
              <p>{t('Try it yourself. Check our fully functional Demo today.')}</p>
              <Button href={links.demo}>{t('Launch the Demo')}</Button>
            </IllustratedContent>
          </IllustratedContainer>
        </section>
      </div>
    </>
  );
};

export default MainPage;
